import React from 'react';
import { Mutation } from 'react-apollo';
import { Layout, Link, Stack, Button } from '@shopify/polaris';
import styled from '@emotion/styled';
import DeleteEventModal from '../DeleteEvent/DeleteEventModal';
import { EDIT_EVENT } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";
import { reportError, formatErrors, hasErrors } from '../../utils/errors';
import { goToPage } from '../../utils/routing';
import { parseBoolean } from '../../utils/parse';
import EventDetailsCard from './Event/EventDetailsCard';
import EventDateCard from './Event/EventDateCard';
import EventLocationCard from './Event/EventLocationCard';
import EventSettingsCard from './Event/EventSettingsCard';
import CopyEventModal from '../EventCopy/CopyEventModal';
import { hasLocations } from '../../utils/features';
import { getHostFromShopDomain } from '../../utils/auth';
import OrganizerCard from './Event/OrganizerCard';

const PageActions = styled.div`
  width:100%;
  margin-top: 40px;
  padding-left:50px;
`;

const EventSettings = ({ shop, event }) => {
  const [deleteEventClicked, setDeleteEventClicked] = React.useState(false);
  const [copyEventOpen, setCopyEventOpen] = React.useState(false);

  const { showToastNotice, showToastError, loadingMarkup, currentShopDomain, isVendor } = useFrame();
  const history = useHistory();

  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  return (
    <>
      <Layout>
          <Layout.AnnotatedSection
            title="Event Details"
            description=<div>
              {isVendor ? (
                <>An event is available to customers on the storefront, these details are used to let your customer know what they're purchasing.</>
              ) : (
                <>An event has an associated Product in your Shopify Admin, go to your <Link external url={'https://' + shop.domain + '/admin/products/' + event.productId + searchParams}>Shopify Admin Product page</Link> to update more details like description, inventory, images, channel visibility, etc.</>
              )}
            </div>
          >
            <EventDetailsCard shop={shop} event={event} />
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Date"
            description="Select the date of your event and whether it is a one-time or recurring event. No date yet? No problem. You can decide that later."
          >
            <EventDateCard shop={shop} event={event} />
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Location"
            description="Where is your event being hosted? You can include a custom link to the venue or livestream."
          >
            <EventLocationCard shop={shop} event={event} />
          </Layout.AnnotatedSection>

          {hasLocations(shop?.domain) && shop.organizers.length > 0 &&
            <Layout.AnnotatedSection
              title="Organizer"
              description=<div>
                <p>Event organizers allow you to assign organizer data to an event that you can use in custom liquid (tickets and notifications) or on the storefront using metafields.</p>
                <p>Organizers can be managed on the <Link external url={'/settings/locations' + searchParams}>organizers settings</Link> page.</p>
              </div>
            >
              <OrganizerCard shop={shop} event={event} />
            </Layout.AnnotatedSection>
          }

          {!event.isMissingProduct &&
              <Layout.AnnotatedSection
                title="Event Visibility"
                description="Do you want to show the event on your store?"
              >
                <EventSettingsCard shop={shop} event={event} />
              </Layout.AnnotatedSection>
          }

          <PageActions>
            <Stack vertical={false} distribution='leading'>
              <Button
                destructive
                onClick={() => setDeleteEventClicked(true)}
              >
                Delete event
              </Button>
              <Button
                disabled={event.isMissingProduct}
                onClick={() => setCopyEventOpen(true)}
              >
                Copy event
              </Button>
            </Stack>
          </PageActions>
      </Layout>
      {deleteEventClicked &&
        <DeleteEventModal
          shop={shop}
          event={event}
          onComplete={() => {
            showToastNotice('Event deleted');
            goToPage({ history, path: '/events' })
          }}
          onError={() => {
            showToastError('Delete failed');
            setDeleteEventClicked(false);
          }}
          onDismiss={() => setDeleteEventClicked(false)}
        />
      }
      {copyEventOpen &&
          <CopyEventModal
            shop={shop}
            event={event}
            onDismiss={() => setCopyEventOpen(false)}
            onCopied={(newEventId) => goToPage({ history, path: `/events/${newEventId}/edit` })}
          />
      }
    </>
  );
}

export default EventSettings;
