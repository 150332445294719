import React from 'react';
import { Mutation } from 'react-apollo';
import { EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION } from '../../utils/graphql';
import { Link as ShopifyLink, Card, TextContainer, Banner, Badge, Stack, Subheading, TextField, Checkbox, ChoiceList } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import ColorPicker from '../shared/ColorPicker.jsx';
import SaveBar from '../shared/SaveBar';
import { shallowEqual } from '../../utils/compare';
import StorefrontScriptCard from './StorefrontScriptCard';
import styled from '@emotion/styled';
import EditRegistrationPageCodeModal from './EditRegistrationPageCodeModal';

const SubtitleContents = styled.div`
  padding: 20px;
`;

const TEXT = [
  {
    handle: 'registration_heading',
    label: 'Page title',
    defaultValue: 'Event Registration',
    helpText: '',
    methods: ['page'],
  },
  {
    handle: 'header_instructions',
    label: 'Header instructions',
    defaultValue: 'Please enter your ticket information before you continue',
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'tickets_completed',
    label: 'Tickets completed',
    defaultValue: 'Tickets Completed',
    helpText: '',
    methods: ['popup'],
  },
  {
    handle: 'checkout',
    label: 'Checkout button title',
    defaultValue: 'Checkout',
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'firstname',
    label: 'First name input label',
    defaultValue: 'First name',
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'lastname',
    label: 'Last name input label',
    defaultValue: 'Last name',
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'email',
    label: 'Email input label',
    defaultValue: 'Email',
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'ticket_number',
    label: 'Ticket number',
    defaultValue: 'Ticket #%{ticket_number}',
    helpText: 'This is only displayed in the ticket title heading when there are more than one ticket in the cart. %{ticket_number} is replaced with the actual number.',
    methods: ['popup', 'page'],
  },
  {
    handle: 'error_unique_email',
    label: 'Error message when email is the same as another ticket in this order',
    defaultValue: 'Email must be different for each ticket.',
    helpText: 'This is only used if you have this validation enabled.',
    methods: ['popup'],
  },
  {
    handle: 'use_same_info',
    label: 'Use same info across tickets checkbox label',
    defaultValue: 'Use the same information for all tickets',
    helpText: 'This is only used if you have the option enabled to show this to the customer.',
    methods: ['popup'],
  },
]

const COLORS = [
  {
    handle: 'header_background_color',
    label: 'Header background color',
    defaultValue: '#F7DC9D',
    helpText: '',
    methods: ['popup'],
  },
  {
    handle: 'header_text_color',
    label: 'Header text color',
    defaultValue: '#8A6100',
    helpText: '',
    methods: ['popup', 'page'],
    defaultValuePage: 'inherit',
    allowInherit: true,
  },
  {
    handle: 'background_color',
    label: 'Background color',
    defaultValue: '#FFFFFF',
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'text_color',
    label: 'Text color',
    defaultValue: '#0F1B41',
    defaultValuePage: 'inherit',
    allowInherit: true,
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'separator_color',
    label: 'Separator color',
    defaultValue: '#B3B9CB',
    helpText: '',
    methods: ['popup'],
  },
  {
    handle: 'input_background_color',
    label: 'Input background color',
    defaultValue: '#FAFBFF',
    defaultValuePage: 'inherit',
    allowInherit: true,
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'input_border_color',
    label: 'Input border color',
    defaultValue: '#B3B9CB',
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'input_text_color',
    label: 'Input text color',
    defaultValue: '#000000',
    defaultValuePage: 'inherit',
    allowInherit: true,
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'button_background_color',
    label: 'Button background color',
    defaultValue: '#2F3CED',
    defaultValuePage: 'inherit',
    allowInherit: true,
    helpText: '',
    methods: ['popup', 'page'],
  },
  {
    handle: 'button_text_color',
    label: 'Button text color',
    defaultValue: '#FFFFFF',
    defaultValuePage: 'inherit',
    allowInherit: true,
    helpText: '',
    methods: ['popup', 'page'],
  },
];

const AttendeeInfoCard = ({ shop }) => {
  const originalStringsContent = shop && JSON.parse(shop.defaultLocaleFile.content);
  const originalStrings = originalStringsContent.events && originalStringsContent.events.storefront || {};
  const originalConfig = JSON.parse(shop && shop.attendeeInfoStorefrontConfig || '{}');

  const [config, setConfig] = React.useState(originalConfig);
  const [strings, setStrings] = React.useState(originalStrings);
  const [showEditTemplateModal, setShowEditTemplateModal] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const defaultLocale = shop.defaultLocaleFile.locale;
  const hasChanges = JSON.stringify(originalConfig) != JSON.stringify(config) || JSON.stringify(originalStrings) != JSON.stringify(strings);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = '/js/online_store_preview.js';
    script.async = true;
    document.body.appendChild(script);
  });

  return (
    <>
      <SubtitleContents>
        <TextContainer>
          <p>The ticket info popup/page allows you to collect attendee information before checkout. The app can show a popup or separate page when the checkout or buy now buttons are clicked on your storefront. Configure the questions asked on the settings page for each event separately.</p>
          <p>Learn more about collecting attendee information <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">in our help center</ShopifyLink> or see it in action on our <ShopifyLink external url='https://eveyevents.myshopify.com'>demo shop</ShopifyLink>.</p>
        </TextContainer>
      </SubtitleContents>
      <StorefrontScriptCard shop={shop} />
      <Mutation key='config' mutation={EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION}
        onError={() => showToastError('Unable to update customizations')}
        onCompleted={() => showToastNotice('Customizations updated')}
        refetchQueries={() => ['StorefrontIntegrationQuery']}
      >
        {(editAttendeeInfoStorefrontIntegration, { loading: saving }) => (
          <Card sectioned
            title='Customize'
            actions={[
              ...(config.attendee_info_method == 'page' ? [{
                content: 'View page',
                url: `${shop.proxyUrl}/register`,
                external: true,
              }] : [{
                content: 'Preview',
                onAction: () => {
                  window.eveyAttendeeInfoModal.setConfig(config || {});
                  window.eveyAttendeeInfoModal.updateStrings({ content: { events: { storefront: strings } } });
                  window.eveyAttendeeInfoModal.setStrings();
                  window.eveyAttendeeInfoModal.show();
                },
              }]),
              ...(config.attendee_info_method == 'page' ? [{
                content: 'Edit template',
                onAction: () => setShowEditTemplateModal(true),
              }] : []),
              {
                content: 'Reset to default',
                onAction: () => {
                  let newConfig = {};
                  COLORS.map((color) => {
                    newConfig[color.handle] = config.attendee_info_method == 'page' && color.defaultValuePage || color.defaultValue;
                  });

                  let newStrings = {};
                  TEXT.map((string) => {
                    newStrings[string.handle] = string.defaultValue;
                  });
                  setConfig(newConfig);
                  setStrings(newStrings);
                }
              },
            ]}
            primaryFooterAction={{
              content: 'Save',
              loading: saving,
              disabled: !hasChanges,
              onAction: () => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }
            }}
          >
            <Stack distribution="fillEvenly" spacing="extraLoose">
              <Stack vertical spacing='loose'>
                <Subheading>PREFERENCES</Subheading>
                <Checkbox
                  checked={config.attendee_info_enabled !== false}
                  label='Collect ticket information for each ticket before checkout'
                  onChange={(v) => setConfig({ ...config, attendee_info_enabled: v })}
                  helpText={`Uncheck this to disable this ${config.attendee_info_method == 'page' ? 'page' : 'popup'} and not ask your attendees any custom ticket information before they check out.`}
                />
                <Banner
                  title="Toggle Evey theme extension"
                  status="info"
                  action={{
                    content: "Toggle Evey theme extension",
                    url: shop.appEmbedUrl,
                    external: true,
                  }}
                >
                  <p>By enabling this feature, you will ensure that you&apos;re capturing all necessary attendee info. This is helpful for customers with fast fingers that accidentally click out of a questionnaire.</p>
                </Banner>
                <ChoiceList
                  title='Select how you want to ask for the ticket information'
                  choices={[
                    { label: 'Show a popup on the product or cart page before checkout', value: 'popup', helpText: 'A popup just before the customer go through checkout. To ensure the information collection feature works properly, we recommend disabling alternative payment methods (Apple Pay, PayPal, or Shop Pay) for your ticket products.' },
                    { label: 'Go to a new page to collect the information before checkout', value: 'page', helpText: 'A separate, fully customizable, page just before checkout. This will not work with buy now or accelerated checkout buttons' },
                  ]}
                  selected={[config.attendee_info_method || 'popup']}
                  onChange={(v) => setConfig({ ...config, attendee_info_method: v[0] })}
                />
                <Checkbox
                  checked={config.unique_email}
                  label='Require a unique email address for each ticket in an order'
                  onChange={(v) => setConfig({ ...config, unique_email: v })}
                  helpText=""
                />
                {!config.unique_email &&
                  <Checkbox
                    checked={config.enable_use_same_info_option}
                    label='Show checkbox that allows the customer to use same ticket information across all tickets for an event'
                    onChange={(v) => setConfig({ ...config, enable_use_same_info_option: v })}
                    helpText=""
                  />
                }

                <TextField
                  label='Custom CSS'
                  type='text'
                  multiline={true}
                  value={config.attendee_info_custom_css}
                  onChange={(v) => setConfig({ ...config, attendee_info_custom_css: v })}
                  helpText='CSS that will be included in the container element of this widget. Prefix with the .evey-attendee-info selector to restrict the scope to only this.'
                />

                <Subheading>COLORS</Subheading>
                {COLORS.filter((c) => c.methods.includes(config.attendee_info_method || 'popup')).map((color) =>
                  <ColorPicker
                    key={color.handle}
                    label={color.label}
                    value={config.attendee_info_method != 'page' && config[color.handle] === 'inherit' ? color.defaultValue : config[color.handle]}
                    helpText={color.helpText}
                    onChange={(v) => setConfig({ ...config, [color.handle]: v })}
                    allowInherit={config.attendee_info_method == 'page' && color.allowInherit}
                    defaultValue={config.attendee_info_method == 'page' && color.defaultValuePage || color.defaultValue}
                    initialValue={color.defaultValue}
                  />
                )}
              </Stack>
              <Stack vertical spacing='loose'>
                <Subheading>TEXT (for default language: {defaultLocale})</Subheading>
                {TEXT.filter((t) => t.methods.includes(config.attendee_info_method || 'popup')).map((text) =>
                  <TextField
                    key={text.handle}
                    label={text.label}
                    value={strings[text.handle] || text.defaultValue}
                    helpText={text.helpText}
                    onChange={(v) => setStrings({ ...strings, [text.handle]: v })}
                  />
                )}
              </Stack>
            </Stack>
            <SaveBar
              show={hasChanges}
              loading={saving}
              onDiscard={() => {
                setConfig(originalConfig);
                setStrings(originalStrings);
              }}
              onSave={() => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }}
            />
            {showEditTemplateModal &&
              <EditRegistrationPageCodeModal
                shop={shop}
                onClose={() => setShowEditTemplateModal(false)}
              />
            }
          </Card>
        )}
      </Mutation>
    </>
  );
};

export default AttendeeInfoCard;
