import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { FormLayout, Layout, Card, TextField, Banner, DatePicker, Checkbox, Tooltip, PageActions, ButtonGroup, Button, TextStyle, Link as ShopifyLink, Autocomplete, Stack } from '@shopify/polaris';
import InitLoading from '../InitLoading.jsx';
import ErrorPage from '../Error/ErrorPage.jsx';
import AppPage from '../AppPage/AppPage.jsx';
import { reportError, formatErrors } from '../../utils/errors';
import { goToPage } from '../../utils/routing';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";
import { convertToDateTime } from '../../utils/time';
import { parseBoolean } from '../../utils/parse';
import { currencySymbolLookup } from '../../utils/money';
import LocationVenueForm from '../EventEdit/Event/LocationVenueForm';
import UpgradeRequiredBanner from '../shared/UpgradeRequiredBanner';
import { hasPlanFeature } from '../../utils/features';
import LocationTransitForm from '../EventEdit/Event/LocationTransitForm';

const GET_SHOP = gql`
  query ShopQuery {
    currentShop {
      id
      domain
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        id
      }
      moneyFormat
      currency
      features
      canUseRecurringEvents
      savedLocations
      locations {
        id
        name
        url
        imageUrl
        address
        description
        seatingChart {
          id
        }
      }
    }
  }
`;

const CREATE_EVENT = gql`
  mutation createEvent($title: String, $location: String, $locationUrl: String, $locationCode: String,
    $destination: String, $destinationUrl: String, $destinationCode: String, $transitType: String,
    $dateType: String, $startAt: String, $endAt: String,
    $inventory: Int, $price: String, $published: Boolean!, $locationType: String, $locationId: ID, $destinationId: ID) {
    createEvent(title: $title, location: $location, locationUrl: $locationUrl, locationCode: $locationCode,
      destination: $destination, destinationUrl: $destinationUrl, destinationCode: $destinationCode,, transitType: $transitType,
      dateType: $dateType, startAt: $startAt, endAt: $endAt,
      inventory: $inventory, price: $price, published: $published, locationType: $locationType, locationId: $locationId, destinationId: $destinationId) {
      event {
        id,
        title,
        location,
        locationUrl,
        locationCode,
        destination,
        destinationUrl,
        destinationCode,
        transitType,
        startAt,
        endAt,
        price,
        published,
        inventory,
        dateType,
        locationType,
        locationId,
        destinationId,
      }
      firstEvent
    }
  }
`;

const EventCreate = () => {
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [title, setTitle] = React.useState('');

  const [location, setLocation] = React.useState('');
  const [locationUrl, setLocationUrl] = React.useState('');
  const [locationCode, setLocationCode] = React.useState('');
  const [destination, setDestination] = React.useState('');
  const [destinationUrl, setDestinationUrl] = React.useState('');
  const [destinationCode, setDestinationCode] = React.useState('');
  const [transitType, setTransitType] = React.useState('flight');

  const [startTime, setStartTime] = React.useState('13:30');
  const [endTime, setEndTime] = React.useState('16:30');
  const [visibleOnStore, setVisibleObStore] = React.useState(true);
  const [ticketPrice, setTicketPrice] = React.useState('');
  const [ticketInventory, setTicketInventory] = React.useState('100');
  const [selected, setSelected] = React.useState(null);
  const [multiDayRange, setMultiDayRange] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);
  const [locationType, setLocationType] = React.useState('venue');
  const [dateType, setDateType] = React.useState('one-time');
  const [locationId, setLocationId] = React.useState(null);
  const [destinationId, setDestinationId] = React.useState(null);

  const { showToastNotice, loadingMarkup, newEventsAllowPublish } = useFrame();
  const history = useHistory();

  const handleDateChange = (value) => setSelected(value);

  const handleMonthChange = (month, year) => {
    setMonth(month);
    setYear(year);
  };

  return (
    <Query query={GET_SHOP}>
      {({ loading, error, data }) => {
        if (error) return <ErrorPage error={error}/>;
        if (loading) return <InitLoading />;

        const shop = data.currentShop;

        const selectedLocation = shop.locations.find((loc) => locationId && loc.id == locationId);
        const hasSeating = selectedLocation && selectedLocation.seatingChart;

        const hasRecurringEventsFeature = hasPlanFeature({ shop, feature: 'recurring' });

        return (
          <Mutation mutation={CREATE_EVENT}
            onError={(error) => {
              if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
                setErrors(error.graphQLErrors[0].errors);
                setUnknownError(false);
              } else {
                setErrors([]);
                setUnknownError(true);
                reportError(error);
              }
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            onCompleted={(data) => {
              if (data && data.createEvent) {
                showToastNotice('Event successfully created');
                const review = data.createEvent.firstEvent ? '?review=true' : ''
                goToPage({ history, path: `/events/${data.createEvent.event.id}/edit${review}` });
              }
            }}
          >
            {(createEvent, { loading }) => (
              <AppPage
                title="New Event"
                breadcrumbs={[
                  {
                    content: 'Events',
                    onAction: () => goToPage({ history, path: '/events' }),
                    target: 'APP'
                  }
                ]}
                primaryAction={{
                  content: 'Save Event',
                  primary: true,
                  loading:  loading,
                  onAction: () => {
                    document.getElementById('createEventForm').querySelector('[type="submit"]').click();
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Back to Events',
                    onAction: () => goToPage({ history, path: '/events' }),
                    target: 'APP'
                  },
                  ...(shop.trialExpired ? [] : [{
                    content: 'Request 1:1 setup call',
                    url: 'https://calendly.com/evey-onboarding-staytuned',
                    target: 'REMOTE',
                    external: true
                  }]),
                  {
                    content: 'Help',
                    url: 'https://evey-events.zendesk.com/hc/en-us/',
                    target: 'REMOTE',
                    external: true
                  },
                ]}
              >
                {unknownError &&
                <Banner
                  title="There was a problem creating the event"
                  status="critical"
                >
                  <p>
                    Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                  </p>
                </Banner>
                }
                {errors && errors.length > 0 &&
                <Banner
                  title="There was a problem creating your event"
                  status="critical"
                >
                  {formatErrors(errors)}
                </Banner>
                }
                {loading && loadingMarkup}
                <Layout>
                  <form id="createEventForm" onSubmit={e => {
                    e.preventDefault();
                    let startDate = null;
                    let endDate = null;
                    if (selected && selected.start && startTime) {
                      startDate = convertToDateTime(selected.start, startTime);
                    }
                    if (selected && selected.end && endTime) {
                      endDate = convertToDateTime(selected.end, endTime);
                    }
                    createEvent({
                      variables:{
                        title: title,
                        location: location,
                        locationUrl: locationUrl,
                        locationCode,
                        destination,
                        destinationUrl,
                        destinationCode,
                        transitType,
                        startAt: startDate,
                        endAt: endDate,
                        price: ticketPrice,
                        published: visibleOnStore,
                        inventory: parseInt(ticketInventory),
                        dateType: dateType,
                        locationType: locationType,
                        locationId: locationId ? locationId : '0',
                        destinationId: destinationId ? destinationId : '0',
                      }
                    });
                  }}>
                    <Layout.AnnotatedSection
                      title="Event Details"
                      description="This will create a product in your Shopify store for this event. You can add extra ticket types as variants on that product after you create it."
                    >
                      <Card sectioned>
                        <FormLayout>
                          <TextField value={title} label="Event Name" placeholder="Awesome Event" onChange={(v) => setTitle(v)} />
                        </FormLayout>
                      </Card>
                    </Layout.AnnotatedSection>

                    <Layout.AnnotatedSection
                      title="Date"
                      description="Select the date of your event and whether it is a one-time or recurring event. No date yet? No problem. You can decide that later."
                    >
                      <Card sectioned>
                        <FormLayout>
                          <ButtonGroup segmented>
                            <Button pressed={dateType == 'one-time'} onClick={() => setDateType('one-time')}>One-time event</Button>
                            <Button pressed={dateType == 'recurring'} onClick={() => setDateType('recurring')}>Recurring event</Button>
                            <Button pressed={dateType == 'no-date'} onClick={() => setDateType('no-date')}>No date yet</Button>
                          </ButtonGroup>
                          {dateType == 'one-time' &&
                            <div style={{ marginTop: '40px' }}>
                              <div className='EventForm__MultiDayCheckbox'>
                                <Checkbox
                                  checked={multiDayRange}
                                  label="Event is more than one day"
                                  onChange={(v) => {
                                    setMultiDayRange(v);
                                    setSelected(null);
                                  }}
                                />
                              </div>
                              <DatePicker
                                month={month}
                                year={year}
                                onChange={handleDateChange}
                                onMonthChange={handleMonthChange}
                                selected={selected}
                                allowRange={multiDayRange}
                                multiMonth={multiDayRange}
                              />
                              <FormLayout.Group condensed>
                                <TextField value={startTime} type="time" label="Start Time" onChange={(v) => setStartTime(v)} placeholder="HH:MM (24-hour)" />
                                <TextField value={endTime} type="time" label="End Time" onChange={(v) => setEndTime(v)} placeholder="HH:MM (24-hour)" />
                              </FormLayout.Group>
                            </div>
                          }
                          {(dateType == 'recurring') &&
                            <>
                              <Banner status="info">You'll be able to set up your event schedule after creating the event.</Banner>
                              {!hasRecurringEventsFeature &&
                                <UpgradeRequiredBanner message='Recurring events are not available on this plan, upgrade to get full access to recurring events features.' />
                              }
                            </>
                          }
                        </FormLayout>
                      </Card>
                    </Layout.AnnotatedSection>

                    <Layout.AnnotatedSection
                      title="Location"
                      description="Where is your event being hosted? You can include a custom link to the venue or livestream."
                    >
                      <Card sectioned>
                        <Stack vertical={true} spacing='loose'>
                          <ButtonGroup segmented>
                            <Button pressed={locationType == 'venue'} onClick={() => setLocationType('venue')}>Venue</Button>
                            <Button pressed={locationType == 'online'} onClick={() => setLocationType('online')}>Online</Button>
                            <Button pressed={locationType == 'transit'} onClick={() => setLocationType('transit')}>Origin / Destination</Button>
                            <Button pressed={locationType == 'no-location'} onClick={() => setLocationType('no-location')}>No location yet</Button>
                          </ButtonGroup>
                          {locationType == 'online' &&
                            <TextStyle variation="subdued">Online events have unique virtual event pages where you can add links to livestreams and more. Visit our help center to <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">learn more</ShopifyLink>.</TextStyle>
                          }
                          {(locationType == 'venue') &&
                            <LocationVenueForm
                              shop={shop}
                              locationType={locationType}
                              name={location}
                              setName={setLocation}
                              url={locationUrl}
                              setUrl={setLocationUrl}
                              locationId={locationId}
                              setLocationId={setLocationId}
                            />
                          }
                          {locationType == 'transit' &&
                            <LocationTransitForm
                              shop={shop}
                              locationType={locationType}
                              transitType={transitType}
                              setTransitType={setTransitType}
                              originName={location}
                              setOriginName={setLocation}
                              originUrl={locationUrl}
                              setOriginUrl={setLocationUrl}
                              originCode={locationCode}
                              setOriginCode={setLocationCode}
                              destinationName={destination}
                              setDestinationName={setDestination}
                              destinationUrl={destinationUrl}
                              setDestinationUrl={setDestinationUrl}
                              destinationCode={destinationCode}
                              setDestinationCode={setDestinationCode}
                              originId={locationId}
                              setOriginId={setLocationId}
                              destinationId={destinationId}
                              setDestinationId={setDestinationId}
                            />
                          }
                        </Stack>
                      </Card>
                    </Layout.AnnotatedSection>

                    {newEventsAllowPublish &&
                      <Layout.AnnotatedSection
                        title="Event Visibility"
                        description="Do you want to show the event on your store?"
                      >
                        <Card sectioned>
                          <FormLayout>
                            <Checkbox
                              checked={visibleOnStore}
                              label="Event visible on store"
                              onChange={(v) => setVisibleObStore(v)}
                              helpText='This can can also be updated in your Shopify Admin area on the Product Page as the Product Status (Active or Draft).'
                            />
                          </FormLayout>
                        </Card>
                      </Layout.AnnotatedSection>
                    }

                    <Layout.AnnotatedSection
                      title="Ticket Types"
                      description="Each ticket type will appear as a separate variant in your Shopify store. You can create multiple ticket types, such as a normal ticket and a VIP ticket. You can also add more ticket types later."
                    >
                      <Card sectioned>
                        <FormLayout.Group>
                          <TextField type="number" prefix={currencySymbolLookup(shop.currency) || '$'} label="Price" placeholder="10" value={ticketPrice} onChange={(v) => setTicketPrice(v)} />
                          {!hasSeating &&
                            <TextField type="number" label="Inventory" value={ticketInventory} placeholder="100" onChange={(v) => setTicketInventory(v)} />
                          }
                        </FormLayout.Group>
                      </Card>
                    </Layout.AnnotatedSection>
                    <PageActions
                      primaryAction={{
                        content: 'Save Event',
                        loading: loading,
                        submit: true
                      }}
                    />
                  </form>
                </Layout>
              </AppPage>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

export default EventCreate;
