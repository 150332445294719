import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { FormLayout, Layout, Card, TextField, Banner, Checkbox, PageActions, ButtonGroup, Button, TextStyle, Link as ShopifyLink } from '@shopify/polaris';
import InitLoading from '../InitLoading.jsx';
import ErrorPage from '../Error/ErrorPage.jsx';
import AppPage from '../AppPage/AppPage.jsx';
import { reportError, formatErrors } from '../../utils/errors';
import { goToPage } from '../../utils/routing';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";
import { parseBoolean } from '../../utils/parse';
import { currencySymbolLookup } from '../../utils/money';

const GET_SHOP = gql`
  query ShopQuery {
    currentShop {
      id
      domain
      moneyFormat
      currency
    }
  }
`;

const CREATE_TICKET_BUNDLE = gql`
  mutation createTicketBundle($title: String, $inventory: Int, $price: String, $published: Boolean!) {
    createTicketBundle(title: $title, inventory: $inventory, price: $price, published: $published) {
      ticketBundle {
        id
      }
    }
  }
`;

const TicketBundleCreatePage = () => {
  const [title, setTitle] = React.useState('');
  const [visibleOnStore, setVisibleObStore] = React.useState(true);
  const [ticketPrice, setTicketPrice] = React.useState('');
  const [ticketInventory, setTicketInventory] = React.useState('100');
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);

  const { showToastNotice, loadingMarkup } = useFrame();
  const history = useHistory();

  const createBundle = (mutation) => {
    mutation({
      variables:{
        title: title,
        price: ticketPrice,
        published: visibleOnStore,
        inventory: parseInt(ticketInventory),
      }
    });
  }

  return (
    <Query query={GET_SHOP}>
      {({ loading, error, data }) => {
        if (error) return <ErrorPage error={error}/>;
        if (loading) return <InitLoading />;

        const shop = data.currentShop;

        return (
          <Mutation mutation={CREATE_TICKET_BUNDLE}
            onError={(error) => {
              if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
                setErrors(error.graphQLErrors[0].errors);
                setUnknownError(false);
              } else {
                setErrors([]);
                setUnknownError(true);
                reportError(error);
              }
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            onCompleted={(data) => {
              if (data && data.createTicketBundle) {
                showToastNotice('Event successfully created');
                goToPage({ history, path: `/events/${data.createTicketBundle.ticketBundle.id}/edit` });
              }
            }}
          >
            {(createTicketBundle, { loading: saving }) => (
              <AppPage
                title="New Ticket Bundle"
                breadcrumbs={[
                  {
                    content: 'Ticket Bundles',
                    onAction: () => goToPage({ history, path: '/events' }),
                    target: 'APP'
                  }
                ]}
                primaryAction={{
                  content: 'Save',
                  primary: true,
                  loading:  saving,
                  onAction: () => createBundle(createTicketBundle),
                }}
                secondaryActions={[
                  {
                    content: 'Back to Events',
                    onAction: () => goToPage({ history, path: '/events' }),
                    target: 'APP'
                  },
                  {
                    content: 'Help',
                    url: 'https://evey-events.zendesk.com/hc/en-us/',
                    target: 'REMOTE',
                    external: true
                  },
                ]}
              >
                {unknownError &&
                <Banner
                  title="There was a problem creating the event"
                  status="critical"
                >
                  <p>
                    Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                  </p>
                </Banner>
                }
                {errors && errors.length > 0 &&
                <Banner
                  title="There was a problem creating your event"
                  status="critical"
                >
                  {formatErrors(errors)}
                </Banner>
                }
                {saving && loadingMarkup}
                <Layout>
                  <Layout.AnnotatedSection
                    title="Bundle details"
                    description="This will create a product in your Shopify store for this ticket bundle."
                  >
                    <Card sectioned>
                      <FormLayout>
                        <TextField value={title} label="Ticket bundle name" placeholder="Season tickets" onChange={(v) => setTitle(v)} />
                        <Checkbox
                          checked={visibleOnStore}
                          label="Event visible on store"
                          onChange={(v) => setVisibleObStore(v)}
                          helpText='This can can also be updated in your Shopify Admin area on the Product Page as the Product Status (Active or Draft).'
                        />
                        <FormLayout.Group>
                          <TextField type="number" prefix={currencySymbolLookup(shop.currency) || '$'} label="Price" placeholder="10" value={ticketPrice} onChange={(v) => setTicketPrice(v)} />
                          <TextField type="number" label="Inventory" value={ticketInventory} placeholder="100" onChange={(v) => setTicketInventory(v)} />
                        </FormLayout.Group>
                      </FormLayout>
                    </Card>
                  </Layout.AnnotatedSection>
                  <PageActions
                    primaryAction={{
                      content: 'Save',
                      loading: saving,
                      onAction: () => createBundle(createTicketBundle),
                    }}
                  />
                </Layout>
              </AppPage>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

export default TicketBundleCreatePage;
