import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { TEMPLATE_PREVIEW } from '../../utils/graphql';
import { Tabs, Layout, Link as ShopifyLink, Card, TextField, FormLayout, Stack, ButtonGroup, Button, Collapsible, Checkbox, Popover, ActionList, Banner, SettingToggle, TextContainer, TextStyle, Select, Autocomplete, Spinner } from '@shopify/polaris';
import { CircleCancelMinor } from '@shopify/polaris-icons';
import EmailPreview from './EmailPreview.jsx';
import ColorPicker from '../shared/ColorPicker.jsx';
import deepEqual from 'deep-equal';
import SharedTemplateModal from '../shared/SharedTemplateModal';
import { useFrame } from '../../contexts/frame';
import MultiTicketEmailModal from './MultiTicketEmailModal';
import TicketNotificationModal from './TicketNotificationModal';
import ReminderModal from './ReminderModal';
import SendTestEmailModal from './SendTestEmailModal';
import { goToPage } from '../../utils/routing';
import { useHistory, useLocation } from "react-router-dom";
import { getShopNameFromDomain } from '../../utils/auth';
import styled from '@emotion/styled';
import { FullHeightContainer } from '../shared/containers';

const CancelSearchButton = styled.div`
  position: absolute;
  right: 0px;
  top: 0;
  z-index: 101;

  .Polaris-Button {
    background:transparent;
    border:none;
  }
`;

const EDIT_EMAIL_CONFIG = gql`
  mutation editEmailConfig($templateId: ID!, $config: String, $hasMultipleEmail: Boolean, $multiTicketThreshold: Int, $attachFiles: Boolean, $preferEmail: Boolean) {
    editEmailConfig(templateId: $templateId, config: $config, hasMultipleEmail: $hasMultipleEmail, multiTicketThreshold: $multiTicketThreshold, attachFiles: $attachFiles, preferEmail: $preferEmail) {
      success
    }
  }
`;

const GET_ATTENDEES = gql`
  query NotificationsPreviewAttendeesQuery($eventId: ID!, $category: String, $filters: String, $search: String) {
    currentShop {
      id
      event(id: $eventId) {
        id
        attendees(eventId: $eventId, category: $category, filters: $filters, search: $search) {
          id
          ticketCode
          email
          phonePretty
          ticketTitle
        }
      }
    }
  }
`;

const TemplateCardContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
`;

const TemplateCardActions = styled.div`
  display:flex;
  flex-wrap:'nowrap';

  @media (max-width: 800px) {
    display:block;
  }
`;

const TemplateCard = ({ title, description, onEdit, onCustomize }) => {
  return (
    <Card sectioned>
      <TemplateCardContainer>
        <div>
          <TextContainer>{title}</TextContainer>
          <TextContainer><TextStyle variation='subdued'>{description}</TextStyle></TextContainer>
        </div>
        <TemplateCardActions>
          <div style={{marginRight: '10px'}}>
            <Button
              onClick={onEdit}
            >
              Edit
            </Button>
          </div>
          <div>
            <Button
              primary
              onClick={onCustomize}
            >
              Customize
            </Button>
          </div>
        </TemplateCardActions>
      </TemplateCardContainer>
    </Card>
  );
};

const NotificationSettings = ({ shop, event, email }) => {
  const shopName = getShopNameFromDomain(shop?.domain);
  const emailConfig = email && JSON.parse(email.config) || {};
  const savedConfig = {
    logo_url: emailConfig.logo_url,
    logo_width: emailConfig.logo_width || 60,
    accent_color: emailConfig.accent_color || '#1990C6',
  };

  const events = email && email.events || [];

  const [selectedTab, setSelectedTab] = React.useState('ticket-email');
  const [config, setConfig] = React.useState(Object.assign({}, savedConfig));
  const [hasMultipleEmail, setHasMultipleEmail] = React.useState(email.hasMultipleEmail);
  const [multiTicketThreshold, setMultiTicketThreshold] = React.useState(email.multiTicketThreshold);
  const [attachFiles, setAttachFiles] = React.useState(email.attachFiles);
  const [showSharedTemplateModal, setShowSharedTemplateModal] = React.useState(false);
  const [moreOptionsActive, setMoreOptionsActive] = React.useState(false);
  const [showTicketNotificationModal, setShowTicketNotificationModal] = React.useState(false);
  const [showMultiTicketEmailModal, setShowMultiTicketEmailModal] = React.useState(false);
  const [showMessageModal, setShowMessageModal] = React.useState(false);
  const [showTestTicketEmailModal, setShowTestTicketEmailModal] = React.useState(false);
  const [showTestMultiTicketEmailModal, setShowTestMultiTicketEmailModal] = React.useState(false);
  const [preferEmail, setPreferEmail] = React.useState(email.preferEmail);
  const [selectedEventId, setSelectedEventId] = React.useState(events.length > 0 ? events[0]?.id : null);
  const [selectedAttendeeId, setSelectedAttendeeId] = React.useState();
  const [attendeeSearch, setAttendeeSearch] = React.useState('');
  const [currentPreview, setCurrentPreview] = React.useState('');

  const { showToastNotice, showToastError, loadingMarkup } = useFrame();
  const history = useHistory();

  const isDirty = !deepEqual(savedConfig, config) || hasMultipleEmail != email.hasMultipleEmail || multiTicketThreshold != email.multiTicketThreshold || attachFiles != email.attachFiles || preferEmail != email.preferEmail;

  const sharedEventsCount = event && shop.defaultNotificationsTemplate.id != email.id ? (event.ticketEmailSharedCount + 1) : email.sharedEventsCount;

  const selectedEvent = event ? event : events.find((e) => e.id == selectedEventId);

  const tabs = [
    {
      id: 'ticket-email',
      content: 'Ticket email',
      accessiblityLabel: 'Email ticket notification',
      panelID: 'ticket-email-content'
    },
    ...(hasMultipleEmail ? [{
      id: 'multi-ticket-email',
      content: 'Multi-ticket email',
      accessiblityLabel: 'Multi-ticket notification',
      panelID: 'multi-ticket-content'
    }] : []),
    {
      id: 'reminder-email',
      content: 'Attendee message email',
      accessiblityLabel: 'Email reminder notification',
      panelID: 'reminder-email-content'
    }
  ];

  return (
    <Layout.Section>
      <Layout>
        <Layout.Section>
          <Card
            title='Preview'
            footerActionAlignment='left'
            secondaryFooterActions={!selectedEvent || selectedTab == 'reminder-email' ? undefined : [
              {
                content: 'Send test email (this feature is temporarily disabled)',
                onAction: () => {},
              }
            ]}
          >
            <Card.Section>
              <FormLayout>
                {!event &&
                  <Select
                    label='Event'
                    labelInline
                    options={events.map((e) => ({ value: e.id, label: e.title }))}
                    value={selectedEvent?.id}
                    onChange={(v) => {
                      setSelectedAttendeeId(null);
                      setSelectedEventId(v);
                      setAttendeeSearch('');
                    }}
                  />
                }
                {selectedEvent &&
                  <Query
                    query={GET_ATTENDEES}
                    variables={{
                      'eventId': selectedEvent?.id,
                      'category': 'all',
                      'filters': JSON.stringify([]),
                      'search': attendeeSearch,
                    }}
                  >
                    {({ loading: attendeesLoading, data }) => {

                      const formatAttendee = (attendee) => (
                        <Stack vertical={false}>
                          <Stack vertical={false} spacing='extraLoose'>
                            <TextStyle variation='subdued'>{attendee.ticketCode}</TextStyle>
                            <div>{attendee.email || attendee.phonePretty}</div>
                            <div>{attendee.ticketTitle}</div>
                          </Stack>
                        </Stack>
                      );

                      const textFormatAttendee = (attendee) => `${attendee.ticketCode} - ${attendee.email || attendee.phonePretty} (${attendee.ticketTitle})`;

                      const attendees = data?.currentShop?.event?.attendees || [];

                      return (
                        <div style={{position:'relative'}}>
                          <Autocomplete
                            allowMultiple={false}
                            loading={attendeesLoading}
                            options={attendees.map((attendee) => ({ value: attendee.id, label: formatAttendee(attendee) }))}
                            selected={[selectedAttendeeId]}
                            textField=<Autocomplete.TextField
                              onChange={setAttendeeSearch}
                              value={attendeeSearch}
                              placeholder={'Sample attendee'}
                              labelHidden
                            />
                            onSelect={(v) => {
                              setSelectedAttendeeId(v[0]);
                              setAttendeeSearch(textFormatAttendee(attendees.find((a) => a.id == v[0])));
                            }}
                            listTitle="Attendees"
                          />
                          {attendeeSearch && attendeeSearch.length > 0 &&
                            <CancelSearchButton>
                              <Button
                                icon={CircleCancelMinor}
                                onClick={() => {
                                  setSelectedAttendeeId(null);
                                  setAttendeeSearch('');
                                }}
                              ></Button>
                            </CancelSearchButton>
                          }
                        </div>
                      );
                    }}
                  </Query>
                }
              </FormLayout>
            </Card.Section>
            <Card.Section>
              <Tabs
                tabs={tabs}
                selected={tabs.findIndex((t) => t.id == selectedTab)}
                onSelect={(v) => setSelectedTab(tabs[v].id)}
              >
                <Query
                  query={TEMPLATE_PREVIEW}
                  variables={{
                    templateType: selectedTab,
                    templateId: email.id,
                    eventId: selectedEvent?.id,
                    attendeeId: selectedAttendeeId,
                    extraContext: JSON.stringify({
                      config: config || {},
                      ...(selectedTab === 'reminder-email' ? {
                        message: 'This is a test.',
                        cta_label: 'View livestream',
                        cta_url: 'https://eveyevents.com'
                      } : {})
                    }),
                  }}
                  onCompleted={(data) => {
                    setCurrentPreview(data.currentShop.template.renderedHtml);
                  }}
                >
                {({ loading: previewLoading, error, data }) => {

                  if (previewLoading) {
                    return (
                      <div style={{textAlign:'center',marginTop:'50px',marginBottom:'50px'}}>
                        <Spinner size="small" />
                      </div>
                    );
                  }

                  return (
                    <div style={{ pointerEvents: 'none' }}>
                      {previewLoading && loadingMarkup}
                      {selectedTab == 'ticket-email' &&
                      <EmailPreview
                        key='ticket-email'
                        shop={shop}
                        event={selectedEvent}
                        subject={email.subject || email.defaultSubject}
                        body={currentPreview || ''}
                        renderHtml={email.subject && email.body ? email.sendAsHtml : true}
                        config={config}
                      />
                      }
                      {hasMultipleEmail && selectedTab == 'multi-ticket-email' &&
                      <EmailPreview
                        key='multi-ticket-email'
                        shop={shop}
                        event={selectedEvent}
                        subject={email.multiTicketSubject || email.defaultMultiTicketSubject}
                        body={currentPreview || ''}
                        renderHtml={email.multiTicketSubject && email.multiTicketBody ? email.multiTicketSendAsHtml : true}
                        config={config}
                      />
                      }
                      {selectedTab == 'reminder-email' &&
                      <EmailPreview
                        key='reminder-email'
                        shop={shop}
                        event={selectedEvent}
                        subject={email.reminderSubject || email.defaultReminderSubject}
                        body={currentPreview || ''}
                        renderHtml={email.reminderSubject && email.reminderBody ? email.reminderSendAsHtml : true}
                        config={config}
                        extraContext={{
                          message: 'This is a test.',
                          cta_label: 'View livestream',
                          cta_url: 'https://eveyevents.com',
                        }}
                      />
                      }
                    </div>
                  );
                }}
                </Query>
              </Tabs>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Mutation mutation={EDIT_EMAIL_CONFIG}
            onError={() => showToastError('Unable to update ticket settings')}
            onCompleted={(data) => showToastNotice('Ticket email settings updated')}
            refetchQueries={() => ['EventQuery', 'GetDefaultNotificationTemplate']}
          >
            {(editEmailConfig, { loading: saving }) => (
              <Card>
                {(event && sharedEventsCount > 1 || !event && sharedEventsCount > 0) &&
                  <Card.Section>
                    <Banner
                      title="Shared template"
                      status="info"
                    >
                      These templates are shared by {sharedEventsCount} event{sharedEventsCount == 1 ? '' : 's'}. Any updates here will apply to all shared events.
                    </Banner>
                  </Card.Section>
                }
                <Card.Section title='Settings'>
                  <FormLayout>
                    <Checkbox
                      checked={hasMultipleEmail}
                      label="Send only one email when someone purchases multiple tickets in a single order"
                      onChange={setHasMultipleEmail}
                    />
                    <Collapsible open={hasMultipleEmail}>
                      <TextField
                        type="number"
                        min="1"
                        step="1"
                        label="Ticket quantity threshold"
                        value={multiTicketThreshold ? multiTicketThreshold.toString() : '1'}
                        placeholder="1"
                        onChange={setMultiTicketThreshold}
                        helpText="This number will be used to determine whether or not this multi-ticket email template will be sent to the customer. If their order contains more than this number of tickets, we will use this template to deliver their tickets."
                      />
                    </Collapsible>
                    <Checkbox
                      checked={attachFiles}
                      label="Attach additional ticket file types to the single ticket email"
                      onChange={setAttachFiles}
                      helpText='Attaching files to emails can affect how your ticket emails are treated by spam filters. It is strongly recommended that you do not attach files to emails.'
                    />
                    <Checkbox
                      checked={preferEmail}
                      label="Send email when both email address and phone number are available for a ticket"
                      onChange={setPreferEmail}
                    />
                  </FormLayout>
                </Card.Section>
                <Card.Section title='Brand settings'>
                  <Stack vertical spacing='loose'>
                    <FormLayout>
                      <TextField
                        label='Logo image URL'
                        value={config.logo_url}
                        helpText=<div>This is the URL of the logo image you want to display in the email header. Upload any custom files through the Shopify <ShopifyLink external url={`https://admin.shopify.com/store/${shopName}/content/files?selectedView=all`} target="_blank">files section</ShopifyLink> and paste the URL here.</div>
                        onChange={(v) => setConfig({ ...config, logo_url: v, })}
                      />
                      <div style={{width:'150px'}}>
                        <TextField
                          label='Logo width (pixels)'
                          value={config.logo_width ? config.logo_width.toString() : '60'}
                          type='number'
                          min={0}
                          max={560}
                          onChange={(v) => setConfig({ ...config, logo_width: v, })}
                        />
                      </div>
                      <ColorPicker
                        label='Accent color'
                        value={config.accent_color}
                        helpText='This color is used for button background colors and link text colors'
                        onChange={(v) => setConfig({ ...config, accent_color: v, })}
                      />
                    </FormLayout>
                    <Stack vertical={false} spacing='loose' distribution='trailing'>
                      {event &&
                        <Popover
                          active={moreOptionsActive}
                          activator={
                            <Button
                              onClick={() => setMoreOptionsActive(true)}
                              disclosure
                            >
                              More
                            </Button>
                          }
                          onClose={() => setMoreOptionsActive(false)}
                        >
                          <ActionList
                            items={[
                              {
                                content: 'Shared template',
                                disabled: saving,
                                onAction: () => setShowSharedTemplateModal(true),
                              },
                            ]}
                          />
                        </Popover>
                      }
                      <Button primary
                        disabled={!isDirty}
                        loading={saving}
                        onClick={() => {
                          editEmailConfig({
                            variables:{
                              templateId: email.id,
                              config: JSON.stringify(config || {}),
                              hasMultipleEmail,
                              multiTicketThreshold: parseInt(multiTicketThreshold),
                              attachFiles,
                              preferEmail,
                            }
                          });
                        }}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Stack>
                </Card.Section>
                <Card.Section title='Templates'>
                  <Stack vertical={true}>
                    <TextContainer><TextStyle variation='subdued'>Visit our help center to <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us/articles/12218015848987-Customizing-Your-Email-Template`} target="_blank">learn more</ShopifyLink> about customizing these templates and a detailed list of liquid variables available to you.</TextStyle></TextContainer>
                    <TemplateCard
                      title='Ticket notification'
                      description='This email is sent to your attendees for each ticket that is purchased.'
                      onEdit={() => setShowTicketNotificationModal(true)}
                      onCustomize={() => {
                        goToPage({
                          history,
                          path: '/editor',
                          params: {
                            template_id: email.id,
                            template_type: 'ticket-email',
                            ...(event ? { event_id: event.id } : {}),
                          },
                        });
                      }}
                    />
                    {email.hasMultipleEmail &&
                      <TemplateCard
                        title='Multi / Group ticket email'
                        description='This template is used for the case when a customer purchases multiple tickets for the same event in a single order or if they purchase a group ticket.'
                        onEdit={() => setShowMultiTicketEmailModal(true)}
                        onCustomize={() => {
                          goToPage({
                            history,
                            path: '/editor',
                            params: {
                              template_id: email.id,
                              template_type: 'multi-ticket-email',
                              ...(event ? { event_id: event.id } : {}),
                            },
                          });
                        }}
                      />
                    }
                    <TemplateCard
                      title='Attendee messages'
                      description='An attendee message email can be sent to your attendees to give them updates on the event, such as new speaker or bands. It can also be used for virtual events to provide a link to a livestream or video sessions.'
                      onEdit={() => setShowMessageModal(true)}
                      onCustomize={() => {
                        goToPage({
                          history,
                          path: '/editor',
                          params: {
                            template_id: email.id,
                            template_type: 'reminder-email',
                            ...(event ? { event_id: event.id } : {}),
                          },
                        });
                      }}
                    />
                  </Stack>
                </Card.Section>
                {showSharedTemplateModal &&
                  <SharedTemplateModal
                    shop={shop}
                    event={event}
                    template={email}
                    templateType='ticket_email'
                    onClose={() => setShowSharedTemplateModal(false)}
                    allowGlobal
                  />
                }
                {showMultiTicketEmailModal &&
                  <MultiTicketEmailModal
                    email={email}
                    onClose={() => setShowMultiTicketEmailModal(false)}
                  />
                }
                {showTicketNotificationModal &&
                  <TicketNotificationModal
                    email={email}
                    onClose={() => setShowTicketNotificationModal(false)}
                  />
                }
                {showMessageModal &&
                  <ReminderModal
                    email={email}
                    onClose={() => setShowMessageModal(false)}
                  />
                }
                {selectedEvent && showTestTicketEmailModal &&
                  <SendTestEmailModal
                    event={selectedEvent}
                    attendeeId={selectedAttendeeId}
                    notificationType='ticket'
                    onClose={() => setShowTestTicketEmailModal(false)}
                  />
                }
                {selectedEvent && showTestMultiTicketEmailModal &&
                  <SendTestEmailModal
                    event={selectedEvent}
                    attendeeId={selectedAttendeeId}
                    notificationType='multi-ticket'
                    onClose={() => setShowTestMultiTicketEmailModal(false)}
                  />
                }
              </Card>
            )}
          </Mutation>
        </Layout.Section>
      </Layout>
    </Layout.Section>
  );
};

export default NotificationSettings;
