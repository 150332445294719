import React from 'react';
import { Query } from 'react-apollo';
import { Card, ResourceList, Avatar, Link, Stack, Button } from '@shopify/polaris';
import LoadingCard from './LoadingCard';
import { GET_CHECKIN_USERS } from '../../../utils/graphql';
import CardHeader from '../CardHeader';
import Gravatar from 'gravatar';
import { goToPage } from '../../../utils/routing';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import EmptyStateCard from './EmptyStateCard';
import DashboardCard from '../DashboardCard';
import { AWS_S3_BUCKET_FILES } from '../../../utils/s3Bucket';
import { CHECKIN_HOST } from '../../../utils/checkinHost';

const UserEmail = styled.div`
  font-weight:bold;
`;

const CheckinCount = styled.div`
  font-weight:normal;
`;

const ListContainer = styled.div`
  padding:12px;
`;

const FooterContainer = styled.div`
  margin: 20px 27px 0 27px;
`;

const CheckinCard = ({ shop, event }) => {
  const history = useHistory();

  return (
    <Query
      query={GET_CHECKIN_USERS}
      variables={{
        'eventId': event.id
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingCard title='Check-In accounts' />;
        }

        // const users = (data?.currentShop?.event?.checkinUsers || []).slice(0, 3);
        const users = [];

        if (users.length == 0) {
          return (
            <EmptyStateCard
              title='Check-In accounts'
              imageUrl={`https://${AWS_S3_BUCKET_FILES}.s3.amazonaws.com/images/dashboard/check-in.svg`}
              message='Control who can check in tickets for your event and monitor check-in activity.'
              action={(
                <Button
                  primary
                  onClick={() => goToPage({ history, path: `/events/${event.id}/edit?section=checkin` })}
                >
                  Manage check-in accounts
                </Button>
              )}
              learnMore='https://evey-events.zendesk.com/hc/en-us/articles/12216774796827-Check-in-Ticket-Type-Settings'
            />
          );
        }

        return (
          <DashboardCard
            title='Check-In accounts'
            headerAction={<Link external url={CHECKIN_HOST}>Open web check-in</Link>}
            primaryAction={{
              content: 'Manage check-in accounts',
              onAction: () => goToPage({ history, path: `/events/${event.id}/edit?section=checkin` }),
            }}
          >
            <ListContainer>
              <Card>
                <ResourceList
                  resourceName={{ singular: 'account', plural: 'accounts' }}
                  items={users}
                  loading={loading}
                  showHeader={true}
                  renderItem={(user) => (
                    <ResourceList.Item
                      id={user.id}
                      accessibilityLabel={`Veiw details for user ${user.email}`}
                      media={<Avatar customer size="medium" name={user.email} source={Gravatar.url(user.email, {d: 'mp'})} />}
                    >
                      <Stack vertical={true} spacing='extraTight'>
                        <UserEmail>{user.email}</UserEmail>
                        <CheckinCount>{`Checked in ${user.checkedinAttendeesCount} tickets`}</CheckinCount>
                      </Stack>
                    </ResourceList.Item>
                  )}
                />
              </Card>
            </ListContainer>
          </DashboardCard>
        );
      }}
    </Query>
  );
};

export default CheckinCard;
